@use "../../styles/variables" as v;

.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border: none;
  border-radius: 10px;
  background: v.$yelow;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  z-index: 10;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1);
  }
  ion-icon {
    color: v.$black;
    font-size: 20px;
  }
}
