@use "../../styles/variables" as v;
.services {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 977px) {
        justify-content: center;
      }
      &-item {
        position: relative;
        padding: 0 40px;
        width: 416px;
        height: 432px;
        margin-top: 24px;
        cursor: pointer;
        background-repeat: no-repeat;
        border-radius: 4px;
        @media (max-width: 422px) {
          width: 350px;
        }
        &-info {
          left: 0;
          top: 0;
          display: none;
          padding: 40px;
          position: absolute;
          border-radius: 4px;
          background-color: v.$yelow;
          color: v.$black;
          width: 100%;
          height: 100%;
          p {
            opacity: 0.8;
            font-weight: 300;
            font-size: 20px;
            line-height: 32px;
          }
        }
        &:hover {
          h5 {
            opacity: 0;
          }
          .services__content-cards-item-info {
            background-color: rgba(v.$yelow, 0.9);
            display: block;
            h5 {
              margin-top: 0;
              margin-bottom: 40px;
              opacity: 1;
            }
          }
        }
        h5 {
          margin-top: 328px;
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
}
