.header {
  padding: 16px 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-nav {
      @media (max-width: 992px) {
        display: none;
      }
      nav {
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0 20px;
          }
        }
      }
    }
    &-btn {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
