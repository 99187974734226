@use "../../styles/variables" as v;

.feedback {
  &__content {
    margin: 0 auto;
    padding: 96px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    .special-label {
      display: none;
    }
    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 625px;
      .alert {
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: #fff;
        color: v.$black;
        box-shadow: 0 0 10px 0px rgba($color: #000000, $alpha: 0.1);
      }
      h2 {
        margin-bottom: 40px;
      }
      p {
        font-weight: 300;
        font-size: 20px;
        line-height: 32px;
        color: v.$black;
        opacity: 0.8;
      }
    }

    &-form {
      width: 100%;
      margin-top: 40px;
      form {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .feedback__content-form-item {
          margin-top: 10px;
          p {
            color: rgb(245, 64, 64);
            font-weight: 300;
            font-size: 16px;
            line-height: 16px;
            margin-top: 5px;
          }
          input {
            border: 2px solid v.$yelow;
            border-radius: 4px;
            padding: 12px 16px;
            min-width: 306px;
            outline: none;
            &::placeholder {
              font-weight: 300;
              font-size: 16px;
              line-height: 16px;
            }
          }
        }
      }
      .btns-primary {
        padding: 16px 35px;
        &:hover {
          border: 2px solid v.$yelow;
        }
      }
    }
  }
}
