@use "../../styles/variables" as v;
.footer {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: v.$black;
  padding: 24px;
  a {
    color: v.$black;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p,
  li,
  a {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.8;
    margin: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }
    &-logo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-adress {
      max-width: 293px;
    }
  }
}
