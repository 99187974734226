@use "../../styles/variables" as v;

.main {
  padding: 160px 0;
  @media (max-width: 765px) {
    padding: 60px 0px;
  }
  &__content {
    padding: 0 120px;
    @media (max-width: 765px) {
      padding: 0 50px;
    }
    &-title {
      max-width: 510px;
      .btns {
        margin-top: 40px;
      }
      &-text {
        display: flex;
        flex-direction: column;
        width: 600px;
        span {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 24px;
          opacity: 0.8;
        }
        h1 {
          font-weight: 500;
          font-size: 56px;
          line-height: 64px;
        }

        @media (max-width: 765px) {
          width: 340px;
          h1 {
            font-size: 32px;
          }
        }
      }
    }
    p {
      margin-top: 40px;
      font-weight: 300;
      font-size: 20px;
      line-height: 32px;
      opacity: 0.8;
    }
  }
  .square {
    position: relative;
    margin-left: -50px;
    width: 166px;
    height: 266px;
    padding: 40px;
    border-left: 8px solid v.$yelow;
    border-top: 8px solid v.$yelow;
    border-bottom: 8px solid v.$yelow;
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      top: 0px;
      right: 0;
      background-color: v.$yelow;
    }
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      bottom: 0px;
      right: 0;
      background-color: v.$yelow;
    }
  }
}
