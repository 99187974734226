@use "../../styles/variables" as v;
.contacts {
  position: relative;
  .cbg {
    position: absolute;
    z-index: -10;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 184px 200px 0 100px;
    @media (max-width: 500px) {
      padding: 100px 40px;
    }
    &-right {
      &-item {
        margin-bottom: 94px;
      }
      p {
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        opacity: 0.8;
        margin-bottom: 24px;
      }
    }
  }
  .square {
    display: flex;
    align-items: center;
    position: relative;
    top: -50px;
    width: 142px;
    height: 152px;
    padding: 40px;
    border-left: 8px solid v.$yelow;
    border-top: 8px solid v.$yelow;
    border-bottom: 8px solid v.$yelow;
    h3 {
      font-size: 40px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      top: 0px;
      right: 0;
      background-color: v.$yelow;
    }
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      bottom: 0px;
      right: 0;
      background-color: v.$yelow;
    }
  }
}
