@use "./styles/variables" as v;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

.App {
  font-family: "Ubuntu", sans-serif;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  color: v.$white;
  .main__bg {
    background-image: url("./img//main.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: v.$white;
  &:hover {
    color: darken($color: v.$white, $amount: 20%);
  }
}

section {
  margin: 96px 0;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: v.$black;
    margin-bottom: 96px;
  }
}
.btns {
  padding: 10px 24px;
  background-color: transparent;
  border: 2px solid v.$yelow;
  border-radius: 4px;
  color: v.$white;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: all 0.3s ease;
  &-primary {
    background-color: v.$yelow;
    padding: 16px 35px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: v.$black;
  }
  &:hover {
    background-color: v.$white;
    color: v.$black;
    border: 2px solid v.$white;
  }
}
