@use "../../styles/variables" as v;

.mobile-menu {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 293;
  display: flex;
  flex-direction: column;
  width: 0px;
  max-width: 100%;
  margin-top: 0px;
  padding-right: 0px;
  align-items: stretch;
  background-color: v.$black;
  transform: translateX(-100%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  .burger-btn {
    position: relative;
    width: 60px;
    margin-left: 80%;
    margin-right: 0;
    &-line {
      right: 0;
      background-color: v.$yelow;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    padding: 24px;
    line-height: 3;
    li {
      list-style: none;
    }
  }
}
