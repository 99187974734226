@use "../../styles/variables" as v;

.advantages {
  position: relative;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -10;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 184px 100px 0 100px;

    @media (max-width: 753px) {
      padding: 100px 0;
    }

    &-right {
      max-width: 550px;
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
      }
      p {
        margin: 40px 0 96px 0;
        font-weight: 300;
        font-size: 20px;
        line-height: 32px;
        opacity: 0.8;
      }
      &-stat {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 56px;
        &-item {
          max-width: 129px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          span {
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            color: v.$yelow;
          }
        }
      }
      @media (max-width: 753px) {
        width: 100%;
      }
    }
  }
  .square {
    display: flex;
    align-items: center;
    position: relative;
    top: -50px;
    width: 142px;
    height: 152px;
    padding: 40px;
    border-left: 8px solid v.$yelow;
    border-top: 8px solid v.$yelow;
    border-bottom: 8px solid v.$yelow;
    h3 {
      font-size: 40px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      top: 0px;
      right: 0;
      background-color: v.$yelow;
    }
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 25px;
      bottom: 0px;
      right: 0;
      background-color: v.$yelow;
    }
  }
}
