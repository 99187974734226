@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slide {
  display: flex !important;
  justify-content: center;
  outline: none;
  img {
    width: 95%;
  }
}

.slick-prev:before {
  content: "";
}
.slick-next:before {
  content: "";
}
